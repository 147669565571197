.home {
    text-align: center; 
    padding: 2rem;
  }
  
  .home-paragraph {
    margin: 0 auto;
    max-width: 600px; 
    font-size: 1.2rem;
    line-height: 1.6;
  }

  .home-image {
    width: 200px; 
    height: 200px; 
    border-radius: 50%; 
    object-fit: cover; 
    border: 4px solid #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem 0; 
  }