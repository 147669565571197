.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #000000;
  }
  
  .navbar-logo a {
    color: #b51e14;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin-left: 1.5rem;
  }
  
  .navbar-links a {
    color: #b51e14
    ;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }

  .navbar-links-container {
    flex: 1; 
    display: flex;
    justify-content: center; 
  }

  .name-overlay {
    position: absolute;
    inset: 1;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: "Arial", sans-serif;
    opacity: 1;
    pointer-events: none;
    color: rgb(0, 0, 0);
    font-size: 5vw; /* Scales with the viewport width */
    text-align: center;
    overflow: hidden;
    word-wrap: break-word;
  }

  .contacts-div {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: #333;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    text-align: left;
  }
  
  .contacts-div p {
    margin: 0;
    margin-bottom: 8px;
  }
  
  .contacts-div p:last-child {
    margin-bottom: 0;
  }
  
  /* Mobile adjustments */
/* Hide contacts section on small screens */
@media (max-width: 768px) {
  .contacts-div {
    display: none;
  }
}